import clsx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

interface ContainerProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  description?: string;
  image?: string;
  type?: string;
  date?: string;
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
  title = 'Cypress Cardiovascular Institute | Cypress, Texas | Heart Health Experts',
  description = `Cypress Cardiovascular Institute based in Cypress, Texas. Our experienced team of interventional cardiologists offers comprehensive and personalized care for a wide range of heart conditions. Contact us today to schedule an appointment and take the first step towards improving your heart health.`,
  image = '/images/hero-with-head-clinicians.jpg',
  type = 'website',
  date,
}) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:url" content={`https://cypresscardiovascularinstitute.com${router.asPath}`} />
        <link rel="canonical" href={`https://cypresscardiovascularinstitute.com${router.asPath}`} />
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {date && <meta property="article:published_time" content={date} />}
      </Head>
      <main className={clsx('min-h-screen antialiased px-6 overflow-x-hidden bg-inherit', className)}>
        {children}
      </main>
    </>
  );
};